<template>
    <div>
        <header>
            <slot name="header"></slot>
        </header>
        <main>
            <slot></slot>
        </main>
        <NotificationToaster></NotificationToaster>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import NotificationToaster from '%/components/Admin/ui/NotificationToaster'

    export default {
        name: 'PaymentsApp',
        components: {
            NotificationToaster,
        },
        props: {
            initUser: {
                type: Object,
                default: null,
            },
            initAccount: {
                type: Object,
                default: null,
            },
        },
        computed: {
            ...mapState({
                user: state => state.user.user,
            }),
        },
        mounted() {
            if (this.initUser) {
                this.$store.commit('user/setUser', this.initUser)
            }
            if (this.initAccount) {
                this.$store.commit('account/setAccount', this.initAccount)
            }
        },
    }
</script>
