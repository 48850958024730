<template>
    <div>
        <b-toast
            v-for="notification in notifications"
            v-bind:key="notification.id"
            :title="notification.title"
            :id="notification.id"
            :variant="notification.variant"
            no-auto-hide
            solid
            visible
            @hidden="onNotificationDismiss(notification)"
        >
            <p class="font-weight-bold">{{ notification.body }}</p>
            <div v-if="notification.exception">
                <b-button variant="outline-danger" size="sm" v-b-modal.exception-detail-modal>Show details</b-button>
                <b-modal
                    id="exception-detail-modal"
                    title="Exception Details"
                    size="xl"
                    ok-only
                >
                    <h4 class="my-2">{{ notification.exception.exception }}</h4>
                    <p class="my-2"><code>{{ notification.exception.file }}:{{ notification.exception.line }}</code></p>
                    <pre class="small"><code>{{ notification.exception.trace }}</code></pre>
                </b-modal>
            </div>
        </b-toast>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'NotificationToaster',
        data() {
            return {
                //
            }
        },
        computed: {
            ...mapState({
                notifications: state => state.notification.notifications,
            }),
        },
        methods: {
            onNotificationDismiss(notification) {
                this.$store.commit('notification/removeNotification', notification)
            },
        },
    }
</script>

<style scoped>

</style>