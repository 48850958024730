<template>
    <b-card title="Gateways" class="h-100">
        <b-table
            id="account-gateways-table"
            striped
            hover
            small
            :fields="fields"
            :items="items"
        >
            <template slot="uuid" slot-scope="data">
                <b-link :href="$laroute.route('admin.accounts.gateways.show', { account: account.id, gateway: data.item.id })">{{ data.value }}</b-link>
            </template>

            <template slot="currencies" slot-scope="data">
                <b-badge v-for="currency in data.value" v-bind:key="currency">{{ currency }}</b-badge>
            </template>

            <template slot="created_at" slot-scope="data">
                <DateDisplay :value="data.value"/>
            </template>
        </b-table>
    </b-card>
</template>

<script>
    import _ from 'lodash'
    import { mapState } from 'vuex'
    import DateDisplay from '%/components/Admin/ui/DateDisplay'

    export default {
        name: 'AccountGatewaysTable',
        components: {
            DateDisplay,
        },
        computed: {
            ...mapState({
                account: state => state.account.account,
            }),
            fields() {
                return {
                    uuid: {
                        label: 'UUID',
                    },
                    type: {
                        label: 'Type',
                    },
                    currencies: {
                        label: 'Currencies',
                    },
                    created_at: {
                        label: 'Created',
                    },
                }
            },
            items() {
                return _.get(this.account, 'gateways', [])
            },
        },
    }
</script>

<style scoped>

</style>