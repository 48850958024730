<template>
    <div>
        <div class="d-flex justify-content-between">
            <b-dropdown size="sm" no-caret>
                <template slot="button-content">
                    Add Filter <plus-icon class="icon-xs"/>
                </template>
                <b-dropdown-item
                    v-for="(field) in fields"
                    v-bind:key="field.name"
                    @click="addFilter(field)"
                >
                    {{ field.label }}
                </b-dropdown-item>
            </b-dropdown>

            <b-link class="text-secondary" @click="resetFilters">Reset</b-link>
        </div>

        <div class="filter-inputs mt-4" v-if="filters.length">
            <FilterInput v-for="(filter, index) in filters" v-bind:key="filter.id" v-model="filters[index]" @delete="deleteFilter(filter.id)"/>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import { PlusIcon, SearchIcon } from 'vue-feather-icons'
    import FilterMenu from '%/components/Admin/ui/FilterWidget/FilterWidgetMenu'
    import FilterInput from '%/components/Admin/ui/FilterWidget/FilterWidgetInput'
    import * as Transaction from '%/generated/constants/models/transaction'

    export default {
        name: 'AccountTransactionsFilters',
        components: {
            SearchIcon,
            FilterInput,
            FilterMenu,
            PlusIcon,
        },
        props: {
            value: {
                type: Array,
            },
        },
        data() {
            return {
                popoverShow: false,
                searchString: '',
                filters: this.value,
                fields: Transaction.FILTER_CONFIG,
            }
        },
        methods: {
            addFilter(field) {
                this.filters.push({
                    id: _.uniqueId('filter-'),
                    field,
                    value: this.getInitialFilterValue(field),
                })
                this.searchString = ''
            },
            deleteFilter(id) {
                // Get the index of the filter based on its ID
                const index = _.findIndex(this.filters, { id })
                // Remove the filter from the collection
                this.filters.splice(index, 1)
            },
            resetFilters() {
                this.filters = []
            },
            /**
             * Compute an initial value for a new filter based on data entered in the search input
             *
             * @param field
             * @returns {string|null}
             */
            getInitialFilterValue(field) {
                if (!this.searchString) {
                    return null
                }

                // Attempt to find an option by its name matching the searchString
                if (field.options) {
                    const optionMatch = _.find(field.options, (option) => {
                        return option.text.toLowerCase().includes(this.searchString)
                    })
                    if (optionMatch) {
                        return optionMatch.value
                    }
                    if (field.type === 'Number') {
                        // Don't allow a string value to be set for number fields
                        return null
                    }
                }

                return this.searchString
            },
        },
        watch: {
            filters: {
                handler(value) {
                    this.$emit('input', value)
                },
                deep: true,
            },
        },
    }
</script>

<style scoped lang="scss">

</style>