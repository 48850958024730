<template>
    <b-container fluid class="container-large" v-if="account">
        <b-container class="mb-5">
            <h1>{{ account.name }}</h1>
        </b-container>
        <b-row>
            <b-col md="4" lg="4" xl="3">
                <account-info/>
            </b-col>
            <b-col>
                <account-gateways-table/>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col md="4" lg="4" xl="3">
                <account-transactions-filters v-model="filters"/>
            </b-col>
            <b-col>
                <account-transactions-table :limit="50" :filters="filters"/>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import { mapState } from 'vuex'

    import AccountInfo from '%/components/Admin/pages/Account/Show/AccountInfo'
    import AccountGatewaysTable from '%/components/Admin/pages/Account/Show/AccountGatewaysTable'
    import AccountTransactionsFilters from '%/components/Admin/pages/Account/Show/AccountTransactionsFilters'
    import AccountTransactionsTable from '%/components/Admin/pages/Account/Show/AccountTransactionsTable'

    export default {
        name: 'AccountShow',
        components: {
            AccountInfo,
            AccountGatewaysTable,
            AccountTransactionsFilters,
            AccountTransactionsTable,
        },
        data() {
            return {
                filters: [],
            }
        },
        computed: {
            ...mapState({
                account: state => state.account.account,
            }),
        },
    }
</script>

<style scoped>

</style>