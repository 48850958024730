<template>
    <b-card v-if="account" title="Account Info" class="h-100">
        <b-table
            id="account-info-table"
            class="mb-0"
            small
            stacked
            :fields="fields"
            :items="items"
        >
            <template slot="created_at" slot-scope="data">
                <DateDisplay :value="data.value"/>
            </template>
        </b-table>
    </b-card>
</template>

<script>
    import { mapState } from 'vuex'
    import DateDisplay from '%/components/Admin/ui/DateDisplay'

    export default {
        name: 'AccountInfo',
        components: {
            DateDisplay,
        },
        computed: {
            ...mapState({
                account: state => state.account.account,
            }),
            fields() {
                return {
                    id: {
                        label: 'ID',
                    },
                    name: {
                        label: 'Name',
                    },
                    code: {
                        label: 'Code',
                    },
                    created_at: {
                        label: 'Created',
                    },
                }
            },
            items() {
                return [
                    this.account,
                ]
            },
        },
    }
</script>

<style lang="scss">
    #account-info-table tbody > tr > :first-child {
        border-top-width: 1px !important;
    }
    #account-info-table {
        border-bottom: 1px solid $gray-200;
    }
</style>