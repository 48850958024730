import axios from 'axios'
import _ from 'lodash'

import laroute from '%/generated/laroute'

import { handleAxiosError } from '%/store/helpers'

// initial state
const state = {
    user: null,
}


// getters
const getters = {}


// actions
const actions = {
    setApiMode({ commit }, apiMode) {
        return new Promise((resolve, reject) => {
            axios.post(laroute.route('admin.api.users.set-api-mode'), {
                api_mode: apiMode,
            })
                .then(({ data }) => {
                    commit('setApiMode', data.api_mode)
                    resolve(data)
                })
                .catch(error => handleAxiosError(error, reject))
        })
    },
}


// mutations
const mutations = {
    setApiMode(state, value) {
        state.user.api_mode = value
    },
    setUser(state, value) {
        state.user = value
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
