<template>
    <b-card title="Transactions" :sub-title="tableInfo" class="h-100">
        <b-pagination
            class="pagination-top"
            size="md"
            align="right"
            :total-rows="total"
            :per-page="limit"
            v-model="currentPage"
        />

        <b-table
            :id="table.id"
            striped
            hover
            small
            :current-page="currentPage"
            :per-page="limit"
            :fields="fields"
            :items="itemsProvider"
            sort-by="created_at"
            sort-desc
        >
            <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>

            <template slot="uuid" slot-scope="data">
                <b-link :href="$laroute.route('admin.accounts.transactions.show', { account: account.id, transaction: data.item.id })">{{ data.value }}</b-link>
            </template>

            <template slot="created_at" slot-scope="data">
                <DateDisplay :value="data.value"/>
            </template>
        </b-table>

        <b-pagination
            class="pagination-bottom mt-4"
            size="md"
            align="right"
            :total-rows="total"
            :per-page="limit"
            v-model="currentPage"
        />
    </b-card>
</template>

<script>
    import _ from 'lodash'
    import { mapState } from 'vuex'
    import TableMixin from '%/mixins/TableMixin'
    import Table from '%/store/classes/Table'
    import DateDisplay from '%/components/Admin/ui/DateDisplay'

    export default {
        name: 'AccountTransactionsTable',
        mixins: [TableMixin],
        components: {
            DateDisplay,
        },
        props: {
            limit: Number,
            filters: Array,
        },
        data() {
            return {
                table: new Table('account-transactions-table', this.dataDispatcher),
            }
        },
        computed: {
            ...mapState({
                account: state => state.account.account,
            }),
            fields() {
                return {
                    uuid: {
                        label: 'UUID',
                    },
                    type: {
                        key: 'type_name',
                        label: 'Type',
                        sortable: true,
                    },
                    status: {
                        label: 'Status',
                        sortable: false, // This is a polymorphic attribute, pretty tricky to sort :grimace:
                    },
                    created_at: {
                        label: 'Created',
                        sortable: true,
                    },
                }
            },
            validFilters() {
                return _.filter(this.filters, (filter) => {
                    return !!filter.value
                })
            },
        },
        methods: {
            dataDispatcher(params) {
                Table.applyFilters(params, this.filters)
                return this.$store.dispatch('account/getTransactions', { account: this.account, params })
            },
            applyFilters: _.debounce(function () {
                this.$root.$emit('bv::refresh::table', this.table.id)
            }, 1000),
        },
        watch: {
            filters: {
                handler() {
                    if (this.filters.length === this.validFilters.length) {
                        this.applyFilters()
                    }
                },
                deep: true,
            },
        },
    }
</script>

<style scoped>
    .pagination-top {
        margin-top: -50px;
    }
</style>