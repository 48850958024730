// Example: https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart/store
import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import notification from './modules/notification'
import transaction from './modules/transaction'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        account,
        notification,
        transaction,
        user,
    },
})
