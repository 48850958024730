import _ from 'lodash'

export default {
    install(Vue, options) {
        if (!_.has(options, 'laroute')) {
            throw new Error('The "laroute" option must be provided.')
        }

        // Get the original method definition for laroute.route()
        const originalRoute = options.laroute.route

        /**
         * Override the route() method to throw an error if a route is not defined
         *
         * @param name
         * @param parameters
         * @param route
         * @returns {*}
         */
        options.laroute.route = function (name, parameters, route) {
            const resolved = originalRoute(name, parameters, route)

            if (resolved === undefined) {
                throw new Error(`Undefined route for name: ${name}.`)
            }

            return resolved
        }

        Vue.prototype.$laroute = options.laroute
    },
}
