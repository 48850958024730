import axios from 'axios'
import _ from 'lodash'

import laroute from '%/generated/laroute'

import { handleAxiosError } from '%/store/helpers'

// initial state
const state = {
    latest: [],
}


// getters
const getters = {}


// actions
const actions = {
    getLatest({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(laroute.route('admin.api.transactions.latest'), {
                params,
            })
                .then(({ data }) => {
                    commit('setLatest', data)
                    resolve(data)
                })
                .catch(error => handleAxiosError(error, reject))
        })
    },
}


// mutations
const mutations = {
    setLatest(state, value) {
        state.latest = value
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
