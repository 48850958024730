// Browser compatibility polyfills/shims
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'mutationobserver-shim'

// Style imports
import '~/app.scss'

// NPM Packages
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import LaroutePlugin from '%/plugins/Laroute'
import Vue from 'vue'

// Local imports
import laroute from '%/generated/laroute'
import store from './store'
import './global-components'


/**
 * Axios setup
 */
const token = document.head.querySelector('meta[name="csrf-token"]')
if (token) {
    Vue.prototype.$csrfToken = token.content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = Vue.prototype.$csrfToken
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'


/**
 * Plugins
 */
Vue.use(BootstrapVue)
Vue.use(LaroutePlugin, { laroute })

// Make VueX globally available through Vue object
Vue.prototype.$vuex = store

/**
 * Initialise Vue
 */
const app = new Vue({
    el: '#app',
    store,
})
