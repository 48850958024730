import _ from 'lodash'
import Vue from 'vue'

export function handleAxiosError(error, reject) {
    // TODO push error message to the store and have a component show a toast
    // https://bootstrap-vue.js.org/docs/components/toast/

    // Create a default error notification object
    const notification = {
        title: 'Error',
        body: error.message,
        variant: 'danger',
    }

    // Axios errors should have a response
    if (_.has(error, 'response')) {
        // If the response has a laravel exception, add it to the notification object
        if (_.has(error, 'response.data.exception')) {
            notification.exception = error.response.data
        } else {
            console.error(error.response)
        }
    } else {
        console.error(JSON.stringify(error))
    }

    // Push the notification onto the notification stack
    Vue.prototype.$vuex.commit('notification/addNotification', notification)

    reject(notification)
}

export function other() {
    // Keeping this here as ESLint doesn't like a single export that isn't "default"
}
