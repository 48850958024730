<template>
    <b-card id="account-transactions-filters" title="Filters" class="h-100">
        <FilterWidget v-model="filters"></FilterWidget>
    </b-card>
</template>

<script>
    import FilterWidget from '%/components/Admin/ui/FilterWidget/FilterWidget'

    export default {
        name: 'AccountTransactionsFilters',
        components: {
            FilterWidget,
        },
        props: {
            value: {
                type: Array,
            },
        },
        data() {
            return {
                filters: this.value,
            }
        },
        watch: {
            filters: {
                handler(value) {
                    this.$emit('input', value)
                },
                deep: true,
            },
        },
    }
</script>

<style scoped lang="scss">

</style>