<template>
    <b-container>
        <h1>Dashboard</h1>

<!--        <b-row>-->
<!--            <b-col>-->
<!--                <b-card title="Charges">-->

<!--                </b-card>-->
<!--            </b-col>-->
<!--            <b-col>-->
<!--                <b-card title="Recurring Charges">-->

<!--                </b-card>-->
<!--            </b-col>-->
<!--            <b-col>-->
<!--                <b-card title="Tokenisations">-->

<!--                </b-card>-->
<!--            </b-col>-->
<!--        </b-row>-->

        <b-card
            title="Latest Transactions"
            :sub-title="`Showing ${transactionsLimit} most recent transactions`"
            class="mt-4"
        >
            <LatestTransactionTable class="mt-4" :limit="transactionsLimit"/>
        </b-card>

    </b-container>
</template>

<script>
    import LatestTransactionTable from './LatestTransactionsTable'

    export default {
        name: 'AdminDashboard',
        components: {
            LatestTransactionTable,
        },
        props: {
            transactionsLimit: {
                type: Number,
                default: 20,
            },
        },
    }
</script>

<style scoped>

</style>