<template>
    <div>
        <b-navbar toggleable="md" type="dark" variant="primary" v-if="user">
            <b-container>
                <b-navbar-brand href="/">Digistorm Payments</b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav v-if="user">
                        <b-nav-item :href="$laroute.route('admin.accounts.index')">Accounts</b-nav-item>
                    </b-navbar-nav>

                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                        <b-nav-form class="bg-light px-2 rounded">
                            <label for="api-mode-input" class="mr-2"><small>Api Mode:</small></label>
                            <b-form-checkbox
                                v-model="apiMode"
                                name="api_mode"
                                id="api-mode-input"
                                value="live"
                                unchecked-value="testing"
                                variant="success"
                                switch
                            >
                                <b class="text-uppercase text-monospace">
                                    <b-badge v-if="apiMode === 'testing'" variant="warning">Test</b-badge>
                                    <b-badge v-if="apiMode === 'live'" variant="success">Live</b-badge>
                                </b>
                            </b-form-checkbox>
                        </b-nav-form>

                        <b-nav-item right v-if="!user">
                            <b-link :href="$laroute.route('login')">Login</b-link>
                        </b-nav-item>

                        <b-nav-item-dropdown right v-if="user">
                            <!-- Using 'button-content' slot -->
                            <template slot="button-content"><em>{{ user.name }}</em></template>
                            <b-dropdown-item :href="$laroute.route('admin.users.profile', { user: user.id })">Profile</b-dropdown-item>
                            <b-dropdown-item @click="logOut">Sign Out</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
            </b-container>
        </b-navbar>

        <b-form :action="$laroute.route('logout')" class="hide" method="post" ref="logoutForm">
            <input type="hidden" name="_token" :value="$csrfToken"/>
        </b-form>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'NavBar',
        computed: {
            ...mapState({
                user: state => state.user.user,
            }),
            apiMode: {
                get() {
                    return this.$store.state.user.user.api_mode
                },
                set(value) {
                    this.$store.dispatch('user/setApiMode', value)
                },
            },
        },
        methods: {
            logOut() {
                this.$refs.logoutForm.submit()
            },
        },
    }
</script>
