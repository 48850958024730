<template>
    <b-form-row class="mb-1">
        <template v-if="filter.field.type === 'String'">
            <b-col sm="2">
                <label>{{ filter.field.label }}</label>
            </b-col>
            <b-col sm="9">
                <b-form-select
                    autofocus
                    v-if="filter.field.options"
                    v-model="filter.value"
                    :options="filter.field.options"
                    size="sm"
                >
                    <template slot="first">
                        <option :value="null" disabled>- Select -</option>
                    </template>
                </b-form-select>
                <b-form-input
                    autofocus
                    v-else
                    size="sm"
                    v-model="filter.value"
                />
            </b-col>
            <b-col sm="1">
                <b-link @click="$emit('delete', filter.id)" class="text-black-50" title="Delete">
                    <trash-2-icon class="icon-sm"/>
                </b-link>
            </b-col>
        </template>

        <template v-if="filter.field.type === 'Number'">
            <b-col sm="2">
                <label>{{ filter.field.label }}</label>
            </b-col>
            <b-col sm="9">
                <b-form-select
                    autofocus
                    v-if="filter.field.options"
                    v-model="filter.value"
                    :options="filter.field.options"
                    size="sm"
                >
                    <template slot="first">
                        <option :value="null" disabled>- Select -</option>
                    </template>
                </b-form-select>
                <b-form-input
                    autofocus
                    v-else
                    type="number"
                    size="sm"
                    v-model="filter.value"
                />
            </b-col>
            <b-col sm="1">
                <b-link @click="$emit('delete', filter.id)" class="text-black-50" title="Delete">
                    <trash-2-icon class="icon-sm"/>
                </b-link>
            </b-col>
        </template>

        <template v-if="filter.field.type === 'Date'">
            <b-col sm="2">
                <label>{{ filter.field.label }}</label>
            </b-col>
            <b-col sm="4">
                <datepicker
                    autofocus
                    v-model="dateFrom"
                    :name="`${filter.id}-date-from`"
                    :disabled-dates="disabledFromDates"
                    bootstrap-styling
                    input-class="form-control-sm"
                ></datepicker>
            </b-col>
            <b-col sm="1" class="text-center"> to </b-col>
            <b-col sm="4">
                <datepicker
                    v-model="dateTo"
                    :name="`${filter.id}-date-to`"
                    :disabled-dates="disabledToDates"
                    bootstrap-styling
                    input-class="form-control-sm"
                ></datepicker>
            </b-col>
            <b-col sm="1">
                <b-link @click="$emit('delete', filter.id)" class="text-black-50" title="Delete">
                    <trash-2-icon class="icon-sm"/>
                </b-link>
            </b-col>
        </template>
    </b-form-row>
</template>

<script>
    import { DateTime } from 'luxon'
    import { Trash2Icon } from 'vue-feather-icons'
    import Datepicker from 'vuejs-datepicker'

    const today = DateTime.local().toJSDate()

    export default {
        name: 'FilterInput',
        components: {
            Datepicker,
            Trash2Icon,
        },
        props: {
            value: Object,
        },
        data() {
            return {
                filter: this.value,
                dateFrom: null,
                dateTo: DateTime.local().toJSDate(),
            }
        },
        computed: {
            disabledFromDates() {
                return {
                    from: this.dateTo < today ? this.dateTo : today,
                }
            },
            disabledToDates() {
                return {
                    from: today,
                }
            },
        },
        methods: {
            setDateValue() {
                const from = DateTime.fromJSDate(this.dateFrom).toISODate()
                const to = DateTime.fromJSDate(this.dateTo).toISODate()
                this.filter.value = `between(${from}, ${to})`
            },
        },
        watch: {
            filter: {
                handler(value) {
                    this.$emit('input', value)
                },
                deep: true,
            },
            dateFrom() {
                this.setDateValue()
            },
            dateTo() {
                this.setDateValue()
            },
        },
    }
</script>

<style scoped>

</style>