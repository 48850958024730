<template>
    <div>
        <b-table
            id="latest-transactions-table"
            striped
            hover
            small
            :per-page="limit"
            :fields="fields"
            :items="itemsProvider"
        >
            <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>

            <template slot="account" slot-scope="data">
                <b-link :href="$laroute.route('admin.accounts.show', { account: data.value.id })">{{ data.value.name }}</b-link>
            </template>

            <template slot="uuid" slot-scope="data">
                <b-link :href="$laroute.route('admin.accounts.transactions.show', { account: data.item.account.id, transaction: data.item.id })">{{ data.value }}</b-link>
            </template>

            <template slot="created_at" slot-scope="data">
                <DateDisplay :value="data.value"/>
            </template>
        </b-table>
    </div>
</template>

<script>
    import Table from '%/store/classes/Table'
    import TableMixin from '%/mixins/TableMixin'
    import DateDisplay from '%/components/Admin/ui/DateDisplay'

    export default {
        name: 'LatestTransactionsTable',
        mixins: [TableMixin],
        components: {
            DateDisplay,
        },
        props: {
            limit: Number,
        },
        data() {
            return {
                table: new Table('latest-transactions-table', this.dataDispatcher),
            }
        },
        computed: {
            fields() {
                return {
                    uuid: {
                        label: 'UUID',
                    },
                    account: {
                        label: 'Account',
                    },
                    type: {
                        key: 'type_name',
                        label: 'Type',
                    },
                    created_at: {
                        label: 'Created',
                    },
                }
            },
        },
        methods: {
            dataDispatcher(params) {
                return this.$store.dispatch('transaction/getLatest', params)
            },
        },
    }
</script>

<style scoped>

</style>