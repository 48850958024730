import Vue from 'vue'

// App
import PaymentsApp from '%/components/Admin/PaymentsApp'

// Layout
import NavBar from '%/components/Admin/layout/NavBar'
import PageContent from '%/components/Admin/layout/PageContent'

// Pages
import AdminDashboard from '%/components/Admin/pages/Home/AdminDashboard'
import AccountShow from '%/components/Admin/pages/Account/Show/AccountShow'

// App
Vue.component('payments-app', PaymentsApp)

// Layout
Vue.component('nav-bar', NavBar)
Vue.component('page-content', PageContent)

// Pages
Vue.component('admin-dashboard', AdminDashboard)
Vue.component('account-show', AccountShow)
