import _ from 'lodash'

export default class Table {
    constructor(id, dataDispatcher) {
        this.id = id
        this.dataDispatcher = dataDispatcher
    }
    static applyFilters(params, filters) {
        _.forEach(filters, (filter) => {
            _.set(params, filter.field.name, filter.value)
        })
    }
}
