<template>
    <span>{{ displayValue }}</span>
</template>

<script>
    import { DateTime } from 'luxon'

    export default {
        name: 'DateDisplay',
        props: {
            value: {
                type: [String,Date],
                required: true,
            },
            format: {
                type: String,
                default: '',
            },
        },
        computed: {
            displayValue() {
                let dateTime
                if (this.value instanceof Date) {
                    dateTime = DateTime.fromJSDate(this.value, { zone: 'utc' })
                } else {
                    dateTime = DateTime.fromSQL(this.value, { zone: 'utc' })
                }
                return dateTime.toLocal().toLocaleString(DateTime.DATETIME_SHORT)
            },
        },
    }
</script>

<style scoped>

</style>