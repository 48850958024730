import axios from 'axios'
import _ from 'lodash'

import laroute from '%/generated/laroute'

import { handleAxiosError } from '%/store/helpers'


// initial state
const state = {
    account: null,
}


// getters
const getters = {}


// actions
const actions = {
    getTransactions({ state }, { account, params }) {
        return new Promise((resolve, reject) => {
            axios.get(laroute.route('admin.api.accounts.transactions.index', { account: account.id }), {
                params,
            })
                .then(response => resolve(response.data))
                .catch(error => handleAxiosError(error, reject))
        })
    },
}


// mutations
const mutations = {
    setAccount(state, value) {
        state.account = value
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
