<template>
    <b-nav vertical fill class="w-100 filter-menu">
        <p class="px-1 pt-1 text-black-50">Please select a field:</p>
        <b-nav-item
            v-for="(field) in fields"
            v-bind:key="field.name"
            @click="$emit('add', field)"
        >
            {{ field.label }}
        </b-nav-item>
    </b-nav>
</template>

<script>
    export default {
        name: 'FilterMenu',
        props: {
            fields: Array,
            value: Array,
        },
        data() {
            return {
                filters: [],
            }
        },
        watch: {
            filters(value) {
                this.$emit('input', value)
            },
        },
    }
</script>

<style scoped>

</style>