import _ from 'lodash'

import Table from '%/store/classes/Table'

export default {
    data() {
        return {
            /** @type Table */
            table: null,
            total: 0,
            currentPage: 1,
        }
    },
    computed: {
        tableInfo() {
            // Get the number of items showing in the table
            const showing = this.total < this.limit ? this.total : this.limit

            return `Showing ${showing} of ${this.total} transactions`
        },
    },
    methods: {
        /**
         * Table data provider.
         *
         * @link https://bootstrap-vue.js.org/docs/components/table/#using-items-provider-functions
         * @param {Object} ctx
         * @param {Number} ctx.currentPage  The current page number (starting from 1)
         * @param {Number} ctx.perPage      The maximum number of rows per page to display
         * @param {String} ctx.sortBy       The current column key being sorted, or an empty string if not sorting
         * @param {Boolean} ctx.sortDesc    The current sort direction (true for descending, false for ascending)
         *
         * @returns {Promise}
         */
        itemsProvider(ctx) {
            const params = {
                page: ctx.currentPage,
                perPage: ctx.perPage,
            }

            if (ctx.sortBy) {
                const sortDirection = (ctx.sortDesc ? 'desc' : 'asc')
                params.sort = `${ctx.sortBy}.${sortDirection}`
            }

            const promise = this.table.dataDispatcher(params)

            return promise
                .then((data) => {
                    this.total = data.meta.total
                    this.currentPage = data.meta.current_page

                    return data.data
                })
                .catch((error) => {
                    // Error toast will be displayed by handleAxiosError() helper
                })
        },
    },
    /**
     * Ensure table data is refreshed when the user 'api_mode' attribute is changed
     */
    mounted() {
        if (!(this.table instanceof Table)) {
            throw new Error('this.table must be an instance of /store/Table.')
        }
        this.$store.watch(
            state => _.get(state, 'user.user.api_mode'),
            (newValue, oldValue) => {
                // Don't refresh if we aren't changing from a previously selected value
                if (newValue && oldValue) {
                    this.$root.$emit('bv::refresh::table', this.table.id)
                }
            },
        )
    },
}
