import axios from 'axios'
import _ from 'lodash'

import laroute from '%/generated/laroute'

import { handleAxiosError } from '%/store/helpers'


// initial state
const state = {
    notifications: [],
}


// getters
const getters = {

}


// actions
const actions = {}


// mutations
const mutations = {
    addNotification(state, notification) {
        notification.id = _.uniqueId()
        state.notifications.push(notification)
    },
    removeNotification(state, notification) {
        const index = _.findIndex(state.notifications, { id: notification.id })
        if (index !== -1) {
            state.notifications.splice(index, 1)
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
